import React from 'react';
import { graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';

import SEO from '../../../components/seo';
import SimpleHero from '../../../components/simple-hero';
import Navigation from '../../../components/navigation';
import { Column, ColumnSection } from '../../../components/column-section';
import RelatedNewsSection from '../../../components/related-news-section';
import { PrimaryButton } from '../../../components/buttons';
import PlaceholderImage from '../../../../static/images/placeholder.jpg';

import { Languages } from '../../../tools/languages';
import getCallToAction from '../../../tools/get-call-to-action';

import HeadingRenderer from './utils/heading-renderer';

const DutchNewsDetailPage = ({ location, data, language = Languages.NL }) => {
  const pathName = location.pathname;
  const newsItem = data.newsItem;
  const { seo } = newsItem;

  return (
    <>
      <SEO
        pathName={pathName}
        title={seo?.title ?? newsItem.title}
        description={seo?.description}
        lang={language}
      />
      <Navigation transparent />
      <main>
        <SimpleHero
          image={
            newsItem.image && newsItem.image.childImageSharp
              ? newsItem.image.childImageSharp.original.src
              : PlaceholderImage
          }
          alt={newsItem.title}
        >
          <h1>{newsItem.title}</h1>
          <p>{newsItem.published}</p>
          <div>
            <PrimaryButton to={`/contact?news=${newsItem.title}`}>
              <FormattedMessage id="hero-contact-cta" />
            </PrimaryButton>
          </div>
        </SimpleHero>
        <ColumnSection>
          <RelatedNewsSection language={language} />
          <Column>
            <ReactMarkdown renderers={{ heading: HeadingRenderer }} linkTarget="_blank">
              {newsItem.content}
            </ReactMarkdown>
          </Column>
        </ColumnSection>
        {getCallToAction(newsItem.CTA, language)}
      </main>
    </>
  );
};

export const query = graphql`
  query DutchNewsDetail($slug: String!) {
    newsItem: strapiNewsItems(slug: { eq: $slug }) {
      slug
      seo: DutchSEO {
        title
        description
      }
      title: title_nl
      content: content_nl
      published(formatString: "D MMMM, YYYY", locale: "NL")
      CTA {
        name
        title: title_nl
        description: description_nl
      }
      image {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  }
`;

export default DutchNewsDetailPage;
